import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Categories from '../components/Categories';
import Layout from '../components/Layout/Layout';
import ScrollHandler from '../components/ScrollHandler';

export type Props = {
  data: any;
  location: any;
};

const Container = styled.section`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
`;

const HeaderDescription = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.brown};
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
const Header = styled.h1`
  margin: 20px 0;
  padding: 5px;
  font-size: 2.4rem;
  text-align: center;
  ${({ theme }) => theme.media.tablet} {
    font-size: 4rem;
  }
`;

const Span = styled.span`
  font-size: 1.4rem;
  width: 80%;
  text-align: center;
  ${({ theme }) => theme.media.tablet} {
    font-size: 2.2rem;
    margin: 10px;
  }
`;

const Menu = ({ data }: Props) => {
  const logo = data.allContentfulImages.edges[0].node.logo.file.url;
  const backgroundDesktop =
    data.allContentfulImages.edges[0].node.backgrounds[0].file.url;
  const [isSticky, setSticky] = useState(false);
  useEffect(() => {
    const changeSize = () => {
      if (window.scrollY >= 120) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };
    window.addEventListener('scroll', changeSize);
    return () => {
      window.removeEventListener('scroll', changeSize);
    };
  }, []);

  return (
    <Layout
      title="Menu"
      logo={logo}
      backgroundDesktop={backgroundDesktop}
      description="Sprawdź nasze menu"
      ogTitle="Menu | Smaki Azji Siemianowice Śląskie"
      ogContent="Sprawdź nasze menu, odwiedź nas ma miejscu ! Smaki Azji Siemianowice Śląskie ul.Śląska 39"
    >
      <Container>
        <ScrollHandler isSticky={isSticky} />
        <HeaderDescription>
          <Header id="top-header">Sprawdź nasze menu</Header>
          <Span>Wybierz na co masz dziś ochotę</Span>
          <Categories {...data} />
        </HeaderDescription>
      </Container>
    </Layout>
  );
};
export default Menu;

export const categoriesQuery = graphql`
  query getCategories {
    allContentfulCategories(sort: { fields: contentfulid, order: ASC }) {
      edges {
        node {
          categoryName
          slug
          backgroundImage {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulImages {
      edges {
        node {
          logo {
            file {
              url
            }
          }
          backgrounds {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
