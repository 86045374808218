import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import styled from 'styled-components';
import BackArrow from '../assets/icons/back-arrow.svg';

const BackToTopButton = styled.button<Props>`
  position: fixed;
  bottom: 5%;
  left: 7%;
  border: 0;
  z-index: 5;
  background: ${({ theme }) => theme.colors.button};
  padding: 20px;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  opacity: ${({ isSticky }) => (isSticky ? 1 : 0)};
  transition: opacity 0.5s linear;
  box-shadow: 1px 1px 3px #5c5b5b;
  ${({ theme }) => theme.media.tablet} {
    height: 70px;
    width: 70px;
    left: 6%;
  }
  ${({ theme }) => theme.media.desktop} {
    display: none;
  }
`;

export type Props = {
  className?: string;
  isSticky?: boolean;
};

const BackImage = styled.img`
  filter: invert(100%) sepia(100%) saturate(120%) brightness(100%)
    contrast(100%);
`;

const ScrollHandler = ({ className, isSticky }: Props) => (
  <BackToTopButton
    className={className}
    isSticky={isSticky}
    type="button"
    onClick={() => scrollTo('#scroll-handler')}
  >
    <BackImage src={BackArrow} alt="Back to top" />
  </BackToTopButton>
);

export default ScrollHandler;
