import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

type Props = {
  node: {
    slug: string;
    categoryName: string;
    backgroundImage?: {
      file: {
        url: string;
      };
    };
  };
};

type ContainerProps = {
  backgroundImage?: string;
};

const Container = styled(Link)<ContainerProps>`
  width: 45%;
  border-radius: 20px;
  margin: 5px;
  display: flex;
  align-items: center;
  text-decoration: none;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-height: 120px;
  box-shadow: 0px 2px 5px gray;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${({ theme }) => theme.media.tablet} {
    min-height: 240px;
    width: 40%;
    margin: 15px;
  }
`;

const CategoryName = styled.span`
  border-radius: 0 0 20px 20px;
  min-height: 30px;
  font-size: 1.1rem;
  opacity: 0.9;
  align-items: center;
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.brown};
  ${({ theme }) => theme.media.tablet} {
    font-size: 2rem;
  }
`;

const Category = ({ node }: Props) => {
  const { slug, categoryName, backgroundImage } = node;
  return (
    <Container
      data-sal="fade"
      data-sal-duration="700"
      data-sal-easing="ease"
      backgroundImage={backgroundImage && backgroundImage.file.url}
      to={`/menu/${slug}`}
    >
      <CategoryName>{categoryName}</CategoryName>
    </Container>
  );
};

export default Category;
