import React from 'react';
import styled from 'styled-components';

import Category from './Category';

export type Props = {
  allContentfulCategories: {
    edges: [
      node: {
        id: number;
        slug: string;
        categoryName: string;
        backogrundImage?: any;
      }
    ];
  };
};

const Container = styled.div`
  margin-top: 15px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  display: flex;
`;

const Categories = ({ allContentfulCategories }: Props) => {
  const { edges } = allContentfulCategories;
  return (
    <Container>
      {edges &&
        edges.map((category, id) => <Category key={id} {...category} />)}
    </Container>
  );
};

export default Categories;
